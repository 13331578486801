// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDJiYCpKAv9oxOLaWZdhK8ZZrQ0LCTcyEo",
  authDomain: "program-eb97d.firebaseapp.com",
  projectId: "program-eb97d",
  storageBucket: "program-eb97d.appspot.com",
  messagingSenderId: "757911808000",
  appId: "1:757911808000:web:1d43e6f8fe6c2e7f3650e5",
  measurementId: "G-GXV4WY73DZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();


export {auth, provider, signInWithPopup, signOut}