const restaurantCategories = [
    "Italiensk",
    "Kinesisk",
    "Japansk",
    "Indisk",
    "Mexicansk",
    "Fransk",
    "Amerikansk",
    "Thai",
    "Middelhavs",
    "Vegetarisk/Vegan"
];

const priceRanges = [
    "0-200 kr",
    "200-400 kr",
    "400-800 kr",
    "800+ kr"
];

const occasions = [
    "Bursdag",
    "Jubileum",
    "Forretningsmøte",
    "Date",
    "Julebord",
    "Vennefest"
];

export { restaurantCategories, priceRanges, occasions };