// src/components/FeaturesPricing.js
import React from 'react';

const FeaturesPricing = () => {
  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-4xl font-bold mb-6 text-center">Our Product</h1>
      <section className="mb-12 flex justify-around">
        <ul className="list-disc list-inside space-y-2">
          <li>Unlimited access</li>
          <li>Best ai generated workouts</li>
          <li>Feature 3: Description of feature 3.</li>
          <li>Feature 4: Description of feature 4.</li>
        </ul>
      </section>
      <section className="flex justify-around">
        <div className="border rounded-lg p-4 w-1/3 text-center">
          <h3 className="text-xl font-semibold mb-2">Life Time access</h3>
          <p className="text-2xl font-bold">$10</p>
          <p className="text-gray-600">Includes all features.</p>
          <button className="btn btn-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">
            Buy Now
          </button>
        </div>
      </section>
    </div>
  );
};

export default FeaturesPricing;
