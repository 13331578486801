import React, { useState } from 'react';

const OptionsCard = ({ label, options, selectedOption, setSelectedOption }) => {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        setSelectedOption(value);
    };

    return (
        <label className="form-control w-full max-w-xs gap-1">
            <div className="label">
                <span className="label-text">{label}</span>
            </div>
            <select 
                className="select select-bordered"
                value={inputValue ? '' : selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
                disabled={!!inputValue}
            >
                <option value="" disabled>Velg alternativ</option>
                {options.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                ))}
            </select>
            <input
                type="text"
                placeholder="Annet"
                className="input input-bordered w-full max-w-xs"
                value={inputValue}
                onChange={handleInputChange}
                maxLength={30}
                pattern="[A-Za-z0-9 ]*" // Allow only alphanumeric characters and spaces
            />
        </label>
    );
};

export default OptionsCard;