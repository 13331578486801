import React, { useState } from 'react';
import { restaurantCategories, priceRanges, occasions } from './valg';
import OptionsCard from '../getbigai/OptionsCard';
import handleRequest from './network/handleRequest';
import Resultat from './Resultat';

const ChooseOptions = () => {
    const [selectedPriceRange, setSelectedPriceRange] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedOccasion, setSelectedOccasion] = useState('');
    const [ekstra, setEkstra] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [result, setResult] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
       
        console.log('Form submitted with values:', {
            selectedPriceRange,
            selectedCategory,
            selectedOccasion,
            ekstra
        });
        try {
            setIsLoading(true);

            const response = await handleRequest(selectedPriceRange, selectedCategory, selectedOccasion, ekstra);
            console.log('Request successful:', response['text']);
            setResult(response['text']);
            setIsSubmitted(true);
            setIsLoading(false);
            setSelectedCategory("");
            setSelectedOccasion("");
            setSelectedPriceRange("");
        } catch (error) {
            console.error('Request failed:', error);
        }
    };

    if (isSubmitted) {
        return(

            <Resultat resultat={result} setIsSubmitted={setIsSubmitted}/>
        )
    }

    return (
        <div className="hero bg-base-200 min-h-screen">
            <div className="hero-content flex-col lg:flex-row-reverse">
                <div className="text-center lg:text-left max-w-lg mx-auto">
                    <img className="lg:w-1/2 mb-10 lg:mt-0 mx-auto shadow-lg" src="Spisute.png" alt="restaurant" />
                    <h1 className="text-5xl font-bold">Finn den perfekte opplevelsen!</h1>
                    <p className="py-6">
                        Vi hjelper deg med å finne den perfekte restauranten for deg og dine venner. Fyll ut skjemaet til venstre og vi vil finne det beste stedet for deg!
                    </p>
                </div>
                <div className="card bg-base-100 w-full max-w-sm shrink-0 shadow-2xl">
                    <form className="card-body" onSubmit={handleSubmit}>
                        <OptionsCard label="Pris per person" options={priceRanges} selectedOption={selectedPriceRange} setSelectedOption={setSelectedPriceRange} />
                        <OptionsCard label="Mat" options={restaurantCategories} selectedOption={selectedCategory} setSelectedOption={setSelectedCategory} />
                        <OptionsCard label="Hva er anledningen?" options={occasions} selectedOption={selectedOccasion} setSelectedOption={setSelectedOccasion} />
                        <input
                            type="text"
                            placeholder="Legg til ekstra informasjon"
                            className="input input-bordered input-primary w-full max-w-xs"
                            value={ekstra}
                            onChange={(e) => setEkstra(e.target.value)}
                            maxLength={100}
                            pattern="[A-Za-z0-9 ]*" // Allow only alphanumeric characters and spaces
                        />
                        {!isLoading ? (
                        <div className="form-control mt-6">
                            <button type="submit" className="btn btn-primary">Søk</button>
                        </div>) : (
                            <button className="btn">
                            <span className="loading loading-spinner"></span>
                                Tenker...
                                </button>
                        )
                    }   
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChooseOptions;