import React from 'react';
import { restaurantCategories, priceRanges, occasions } from './valg';
import OptionsCard from '../getbigai/OptionsCard';
import handleRequest from './network/handleRequest';

const Resultat = ({ resultat, setIsSubmitted }) => {
    return (
        <div className="hero bg-base-200 min-h-screen">
            <div className="hero-content flex-col lg:flex-row-reverse">
                <div className="text-center lg:text-left max-w-lg mx-auto">
                    <img className="lg:w-1/2 mb-10 lg:mt-0 mx-auto shadow-lg" src="Spisute.png" alt="restaurant" />
                    {/* <h1 className="text-5xl font-bold text-primary mb-4">Her er vår anbefaling</h1> */}
                </div>
                <div className="card bg-base-100 w-full max-w-sm shrink-0 shadow-2xl p-6">
                    <h1 className="text-2xl font-bold text-center  mb-4">{resultat["navn"]}</h1>
                    <p className="text-lg text-gray-700 mb-4">{resultat["beskrivelse"]}</p>
                    <p className="text-lg text-gray-700 mb-4">Rating: {resultat["rating"]}</p>
                    <div className="form-control mt-6">
                        <button onClick={() => setIsSubmitted(false)} className="btn btn-primary">Søk på nytt</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Resultat;