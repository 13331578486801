import sendRequest from './sendRequest.js';

async function handleRequest(pris, mat, anledning, ekstra) {
    console.log('Sending request:', pris, mat, anledning, ekstra);

    try {
        const responseData = await sendRequest(pris, mat, anledning, ekstra);
        // Handle the response data
        console.log('Received response:', responseData);
        return responseData;
    } catch (error) {
        // Handle any errors
        console.error('Request failed:', error);
    }
}

export default handleRequest;