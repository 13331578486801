
async function sendRequest(pris, mat, anledning, ekstra) {
    const url = 'https://get-resturant-bseryhuhrq-uc.a.run.app'; // Replace with your Firebase function URL

    const requestData = {
        "pris": pris,
        "mat": mat,
        "anledning": anledning,
        "ekstra": ekstra
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        console.log('Response:', responseData);
        return responseData;

    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export default sendRequest;