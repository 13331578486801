import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import Home from './landing/Home';
import FeaturesPricing from './landing/FeaturesPricing';
import OptionsCard from './getbigai/OptionsCard';
import ChooseOptions from './getbigai/ChoseOptions';

function App() {
  return (
    <Router>
      <div className="min-h-screen overflow-x-hidden">
        <nav className="p-4 bg-gray-800 text-white">
          {/* <ul className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/features-pricing">Features & Pricing</Link></li>
            <li><Link to="/getbigai">Get Big AI</Link></li>
          </ul> */}
        </nav>
        <main className="p-4">
          <Routes>
            <Route path="/" element={<ChooseOptions />} />
            <Route path="/features-pricing" element={<FeaturesPricing />} />
            <Route path="/getbigai" element={<ChooseOptions/>} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
